import React from 'react';
import PropTypes from 'prop-types';
import Publication from '../Publication';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { gridSpanCalc } from '../../base/Layout/Grid';

const useStyles = makeStyles(theme => ({
  root: {
    '& $title': {
      color: theme.palette.text.headline,
      paddingLeft: theme.inset.small,
      margin: [[theme.stack.medium, 0, theme.stack.xsmall, 0]]
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.grid.sm.inset
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.grid.md.inset,
      '& $title': {
        paddingLeft: 0,
        marginTop: theme.stack.large,
        marginBottom: theme.stack.small,
        marginLeft: gridSpanCalc(theme, 'md', 1)
      },
      '& $publication': {
        marginLeft: gridSpanCalc(theme, 'md', 1)
      }
    },
    [theme.breakpoints.up('lg')]: {
      '& $title': {
        paddingLeft: theme.inset.medium
      }
    }
  },
  publication: {},
  title: {}
}));

const PublicationList = props => {
  const { title, publications, titleLevel, className } = props;
  const classes = useStyles(props);
  const titleComponent = titleLevel ? `h${titleLevel}` : 'h2';

  return (
    <div className={clsx(classes.root, className)}>
      {title && (
        <Typography
          className={classes.title}
          component={titleComponent}
          variant="h4"
        >
          {title}
        </Typography>
      )}
      {publications.map((publicationProps, index) => (
        <Publication
          className={classes.publication}
          key={index}
          {...publicationProps}
        />
      ))}
    </div>
  );
};

PublicationList.propTypes = {
  title: PropTypes.string,
  publications: PropTypes.array.isRequired,
  className: PropTypes.string,
  titleLevel: PropTypes.number
};

PublicationList.defaultProps = {
  titleLevel: 2
};

export default PublicationList;
