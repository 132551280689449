import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '../../base/Icons/ExpandMoreRounded';
import Typography from '@material-ui/core/Typography';
import Link from '../../atoms/Link';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    '&::before': {
      backgroundColor: theme.palette.background.paper
    },
    '& $summary': {
      padding: [[0, theme.inset.small]],
      backgroundColor: theme.palette.grey[100],
      '@media(hover:hover)': {
        '&:hover': {
          '& $summaryTitle': {
            textDecoration: 'underline'
          },
          '& $summaryExpandIcon': {
            backgroundColor: theme.palette.grey[200]
          }
        }
      }
    },
    '& $summaryContent': {
      margin: [[theme.stack.small, theme.inline.small, theme.stack.small, 0]],
      display: 'flex',
      flexDirection: 'column'
    },
    '& $summaryOverline': {
      color: theme.palette.text.mainLowContrast,
      marginBottom: theme.stack.xsmall,
      display: 'block'
    },
    '& $summaryTitle': {
      color: theme.palette.text.main,
      display: 'block'
    },
    '& $summaryExpandIcon': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.main
    },
    '& $details': {
      color: theme.palette.text.main,
      backgroundColor: theme.palette.grey[100],
      display: 'flex',
      flexDirection: 'column',
      padding: [[0, theme.inset.small, theme.stack.small, theme.inset.small]]
    },
    '& $detailsDescription': {
      marginBottom: theme.stack.small
    },
    '& $detailsProperties': {
      margin: 0
    },
    '& $detailsPropertyValue::after': {
      content: '""',
      display: 'block',
      marginBottom: theme.stack.small
    },
    [theme.breakpoints.up('md')]: {
      '& $summaryContent': {
        margin: [
          [theme.stack.medium, theme.inline.xlarge, theme.stack.medium, 0]
        ]
      },
      '& $summary': {
        padding: [[0, theme.inset.medium]]
      },
      '& $details': {
        padding: [
          [0, theme.inset.medium, theme.stack.large, theme.inset.medium]
        ]
      },
      '& $detailsDescription': {
        marginRight: theme.spacing(9),
        marginBottom: theme.stack.medium
      },
      '& $detailsProperties': {
        columns: 2,
        columnGap: theme.spacing(4),
        marginBottom: theme.stack.medium
      },
      '& $detailsPropertyName': {
        marginBottom: theme.stack.xsmall
      },
      '& $detailsPropertyValue': {
        breakBefore: 'avoid',
        breakInside: 'avoid'
      }
    },
    '&$expanded': {
      margin: 0,
      '& $summary': {
        backgroundColor: theme.palette.background.paper
      },
      '& $summaryTitle': {
        color: theme.palette.text.headline
      },
      '& $details': {
        backgroundColor: theme.palette.background.paper
      },
      '& $summaryExpandIcon': {
        backgroundColor: theme.palette.grey[100]
      }
    }
  },
  expanded: {},
  details: {},
  detailsDescription: {},
  detailsProperties: {},
  detailsPropertyName: {},
  detailsPropertyValue: {},
  summary: {},
  summaryContent: {},
  summaryOverline: {},
  summaryTitle: {},
  summaryExpandIcon: {}
}));

const Publication = props => {
  const {
    shortDescription,
    title,
    contributors,
    journalTitle,
    publicationDate,
    url,
    className
  } = props;
  const theme = useTheme();
  const isBreakpointUpMedium = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles(props);
  const properties = props =>
    /* eslint-disable react/prop-types */
    props.map((prop, index) => {
      const { name, value } = prop;

      return (
        <React.Fragment key={index}>
          <Typography
            className={classes.detailsPropertyName}
            variant="body1"
            component="dt"
          >
            {name}
          </Typography>
          <Typography
            className={classes.detailsPropertyValue}
            variant="caption"
            component="dd"
          >
            {value}
          </Typography>
        </React.Fragment>
      );
    });

  return (
    <Accordion
      classes={{
        root: clsx(classes.root, className),
        expanded: classes.expanded
      }}
      elevation={0}
      square={true}
      component="article"
    >
      <AccordionSummary
        classes={{
          root: classes.summary,
          content: classes.summaryContent,
          expandIcon: classes.summaryExpandIcon
        }}
        expandIcon={
          <ExpandMoreIcon
            fontSize={isBreakpointUpMedium ? 'default' : 'small'}
          />
        }
        IconButtonProps={{
          disableRipple: true,
          disableFocusRipple: true,
          edge: false
        }}
      >
        <Typography className={classes.summaryOverline} variant="overline">
          {journalTitle}
        </Typography>
        <Typography
          className={classes.summaryTitle}
          variant="h5"
          component="h3"
        >
          {title.main}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {shortDescription && (
          <Typography
            className={classes.detailsDescription}
            variant="body1"
            dangerouslySetInnerHTML={{ __html: shortDescription }}
          />
        )}
        <dl className={classes.detailsProperties}>
          {properties([
            {
              name: 'Contributors',
              value: contributors
                .map(contributor => `${contributor.creditName}`)
                .join(', ')
            },
            {
              name: 'Publication Date',
              value: [
                publicationDate.year,
                publicationDate.month,
                publicationDate.day
              ]
                .filter(item => item !== null)
                .join('-')
            }
          ])}
        </dl>
        <Link to={url} variant="external">
          Read Article
        </Link>
      </AccordionDetails>
    </Accordion>
  );
};

Publication.propTypes = {
  title: PropTypes.shape({
    main: PropTypes.string.isRequired,
    subtitle: PropTypes.string
  }),
  shortDescription: PropTypes.string,
  contributors: PropTypes.arrayOf(
    PropTypes.shape({
      creditName: PropTypes.string.isRequired,
      role: PropTypes.string
    })
  ),
  journalTitle: PropTypes.string,
  publicationDate: PropTypes.shape({
    year: PropTypes.number,
    month: PropTypes.number,
    day: PropTypes.number
  }),
  url: PropTypes.string,
  className: PropTypes.string
};

Publication.defaultProps = {};

export default Publication;
