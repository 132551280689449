import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { gridSpanCalc } from '../../base/Layout/Grid';
import clsx from 'clsx';

const useStyles = makeStyles(theme => {
  const adjustHeadline = style => ({
    ...style,
    color: theme.palette.text.headline,
    margin: [['1.15em', 0, '0.5em']]
  });

  return {
    root: {
      display: 'block',
      color: theme.palette.text.main,
      padding: [[0, theme.grid.xs.inset]],
      '@global': {
        '* > :last-child': {
          marginBottom: 0
        },
        '* > :first-child': {
          marginTop: 0
        },
        h1: adjustHeadline(theme.typography.h2),
        h2: adjustHeadline(theme.typography.h3),
        h3: adjustHeadline(theme.typography.h4),
        h4: adjustHeadline(theme.typography.h5),
        h5: adjustHeadline(theme.typography.h6),
        h6: adjustHeadline(theme.typography.h6),
        a: {
          textDecoration: 'underline',
          color: theme.palette.primary.dark,
          '@media(hover:hover)': {
            '&:hover': {
              color: theme.palette.text.main
            }
          }
        },
        'ul, ol': {
          paddingLeft: theme.spacing(2)
        },
        img: {
          maxWidth: '100%'
        },
        p: {
          margin: [['1em', 0]]
        },
        table: {
          width: '100%',
          margin: [[0, 0, '1.5em']],
          '& th, td': {
            color: theme.palette.text.main,
            padding: [[theme.inset.xsmall, theme.inset.small]]
          },
          '& thead': {
            backgroundColor: theme.palette.grey['200'],
            color: theme.palette.secondary.contrastText
          },
          '& tbody tr': {
            backgroundColor: theme.palette.grey['100']
          }
        },
        blockquote: {
          margin: [[theme.stack.small, 0]],
          marginLeft: theme.inset.xsmall,
          fontStyle: 'italic',
          '& p': {
            margin: 0,
            marginBottom: theme.stack.xsmall,
            '&::before': {
              content: '"\\201C" '
            },
            '&::after': {
              content: '"\\201D" '
            }
          },
          '& footer': {
            ...theme.typography.caption,
            textAlign: 'right',
            marginTop: theme.spacing(-1)
          }
        }
      },
      '& .footnotes': {
        ...theme.typography.caption,
        margin: [[theme.stack.small, 0, theme.stack.large]],
        '& hr': {
          color: theme.palette.grey['300'],
          borderBottom: 'none'
        },
        '& li>p': {
          display: 'inline'
        }
      },
      '& sup[id^=fnref]': {
        paddingLeft: '1px',
        paddingRight: '4px',
        marginTop: '-70px',
        paddingTop: '70px'
      },
      '& .gatsby-resp-image-figure': {
        margin: [[theme.spacing(5), 0]],
        position: 'relative',
        '&::before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          bottom: 0,
          height: '80%',
          width: '100vw',
          margin: '0 calc(50% - 50vw)',
          backgroundColor: theme.palette.primary.main
        }
      },
      '& .gatsby-resp-image-figcaption': {
        ...theme.typography.caption,
        position: 'relative',
        color: theme.palette.text.light,
        padding: [[theme.inset.xsmall, 0, theme.inset.small]]
      },
      '& $article': {
        ...theme.typography.body1,
        display: 'block',
        padding: 0
      },
      [theme.breakpoints.up('sm')]: {
        padding: [[0, theme.grid.sm.inset]],
        '& $article': {
          padding: [[0, theme.inset.small]]
        },
        '& .gatsby-resp-image-figcaption': {
          padding: [[theme.inset.small, 0, theme.inset.medium]]
        }
      },
      [theme.breakpoints.up('md')]: {
        padding: [[0, theme.grid.md.inset]],
        '& $article': {
          paddingLeft: 0,
          paddingRight: 0,
          marginLeft: gridSpanCalc(theme, 'md', 1),
          marginRight: gridSpanCalc(theme, 'md', 1)
        },
        '& .gatsby-resp-image-figcaption': {
          padding: [[theme.inset.small, 0, theme.inset.medium]]
        }
      },
      [theme.breakpoints.up('lg')]: {
        '& $article': {
          paddingLeft: theme.inset.medium,
          paddingRight: theme.inset.medium
        }
      }
    },
    article: {}
  };
});

const Markdown = props => {
  const { content, className } = props;
  const classes = useStyles(props);

  if (!content) {
    return '';
  }

  return (
    <div className={clsx(classes.root, className)}>
      <article className={classes.article}>{content}</article>
    </div>
  );
};

export const MarkdownHtml = props => {
  const { content, className } = props;
  const classes = useStyles(props);

  if (!content) {
    return '';
  }

  return (
    <div className={clsx(classes.root, className)}>
      <article
        className={classes.article}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};

Markdown.propTypes = {
  className: PropTypes.string,
  content: PropTypes.node
};

MarkdownHtml.propTypes = Markdown.propTypes;

export default Markdown;
