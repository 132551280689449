import { graphql } from 'gatsby';
import { markdownRemark } from '../helper/data';

export function mapToProps(data = {}, isCms = false) {
  const mdData = isCms ? data : markdownRemark(data);

  return {
    content: mdData.html
  };
}

export const fragment = graphql`
  fragment MarkdownContent on MarkdownRemark {
    html
  }
`;
