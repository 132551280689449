import Meta from '../../components/Meta.js';
import PropTypes from 'prop-types';
import PublicationsTemplate from '../../components/templates/PublicationPage';
import React from 'react';
import withRoot from '../../hoc/withRoot';
import { BreadcrumbJsonLd, JsonLd } from 'gatsby-plugin-next-seo';
import { MarkdownHtml } from '../../components/organisms/Markdown';
import { getProps as footerProps } from '../utils/mapper/footer';
import { graphql } from 'gatsby';
import { mapToProps as mapToIntroProps } from '../utils/mapper/intro';
import { mapToProps as mapToMarkdownProps } from '../utils/mapper/markdown';
import { mapToProps as mapToMetaProps } from '../utils/mapper/meta';
import {
  mapToProps as mapToJournalArticles,
  mapToJsonLdProps as mapToJournalJsonLdProps
} from '../utils/mapper/journalArticles';
import {
  mapToProps as mapToOtherAtricles,
  mapToJsonLdProps as mapToOtherJsonLdProps
} from '../utils/mapper/otherArticles';
import { mapToProps as mapToTopbarProps } from '../utils/mapper/topbar';
import { frontmatter, siteMetadata } from '../utils/helper/data';

const PublicationsPage = props => {
  const { location, data } = props;
  const pathname = location.pathname;
  const smData = siteMetadata(data);
  const fmData = frontmatter(data);

  return (
    <>
      <Meta {...mapToMetaProps(data, location)} />
      <BreadcrumbJsonLd
        itemListElements={[
          {
            position: 1,
            name: smData.title,
            item: smData.siteUrl
          },
          {
            position: 2,
            name: fmData.title,
            item: smData.siteUrl + pathname
          }
        ]}
      />
      {mapToJournalJsonLdProps(data).map((articleJsonLdProp, index) => (
        <JsonLd key={index} json={articleJsonLdProp} />
      ))}
      {mapToOtherJsonLdProps(data).map((articleJsonLdProp, index) => (
        <JsonLd key={index} json={articleJsonLdProp} />
      ))}
      <PublicationsTemplate
        journalArticles={mapToJournalArticles(data)}
        otherArticles={mapToOtherAtricles(data)}
        markdownComponent={MarkdownHtml}
        MarkdownProps={mapToMarkdownProps(data)}
        TopBarProps={mapToTopbarProps(data, location)}
        IntroProps={mapToIntroProps(data)}
        FooterProps={footerProps()}
      />
    </>
  );
};

PublicationsPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

export default withRoot(PublicationsPage);

export const pageQuery = graphql`
  query PublicationsTemplate {
    site {
      siteMetadata {
        ...ArticleJsonLdSiteMetadata
        ...MetaSiteMetadata
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "publicationsPage" } }) {
      frontmatter {
        title
        description
        ...MetaFrontMatter
        ...Intro
      }
      ...MarkdownContent
    }
    ...TopBar
    ...JournalArticles
    ...OtherArticles
  }
`;
