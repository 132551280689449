import { graphql } from 'gatsby';
import { stripTags, orcidWorkUrl } from '../helper/transformation';

export function mapToProps(data = {}) {
  const otherArticles = data.otherArticles?.edges ?? [];

  return otherArticles.map(edge => ({
    ...edge.node,
    url: orcidWorkUrl(edge.node),
    shortDescription: stripTags(edge.node?.shortDescription)
  }));
}

export function mapToJsonLdProps(data) {
  const otherArticles = data.otherArticles?.edges ?? [];

  return otherArticles.map(edge => {
    const node = edge.node;

    return {
      '@context': 'https://schema.org',
      '@type': 'CreativeWork',
      identifier: node.url,
      url: orcidWorkUrl(node),
      name: node.title?.main ?? '',
      description: node.shortDescription,
      datePublished: node.publicationDate
        ? new Date(
            node.publicationDate?.year,
            node.publicationDate?.month,
            node.publicationDate?.day
          )
        : null
    };
  });
}

export const fragment = graphql`
  fragment OtherArticles on Query {
    otherArticles: allOrcidWork(
      filter: { type: { eq: "other" } }
      sort: {
        fields: [
          publicationDate___year
          publicationDate___month
          publicationDate___day
        ]
        order: [DESC, DESC, DESC]
      }
    ) {
      edges {
        node {
          id
          title {
            main
            subtitle
          }
          publicationDate {
            year
            month
            day
          }
          shortDescription
          contributors {
            creditName
            role
          }
          path
          url
          externalIds {
            type
            url
            value
          }
        }
      }
    }
  }
`;
