import React from 'react';
import PropTypes from 'prop-types';
import TopBar from '../../organisms/TopBar';
import Main from '../../organisms/Main';
import Markdown, { MarkdownHtml } from '../../organisms/Markdown';
import Intro from '../../organisms/Intro';
import PublicationList from '../../organisms/PublicationList';
import Footer from '../../organisms/Footer';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  intro: {},
  main: {},
  publications: {
    marginBottom: theme.stack.small,
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.stack.large
    }
  },
  markdown: {
    marginBottom: theme.stack.medium,
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.stack.large
    }
  }
}));

const PublicationPage = props => {
  const {
    journalArticles,
    otherArticles,
    markdownComponent,
    MarkdownProps,
    TopBarProps,
    IntroProps,
    FooterProps
  } = props;
  const MarkdownComponent = markdownComponent || Markdown;
  const classes = useStyles(props);
  const showListTitle =
    journalArticles?.length > 0 && otherArticles?.length > 0;

  return (
    <>
      <TopBar {...TopBarProps} />
      <Main className={classes.main}>
        <Intro className={classes.intro} {...IntroProps} />
        <MarkdownComponent className={classes.markdown} {...MarkdownProps} />
        {journalArticles && (
          <PublicationList
            title={showListTitle && 'Journal Articles'}
            className={classes.publications}
            publications={journalArticles}
          />
        )}
        {otherArticles && (
          <PublicationList
            title={showListTitle && 'Other Articles'}
            className={classes.publications}
            publications={otherArticles}
          />
        )}
      </Main>
      <Footer {...FooterProps} />
    </>
  );
};

PublicationPage.propTypes = {
  journalArticles: PropTypes.array.isRequired,
  otherArticles: PropTypes.array,
  markdownComponent: PropTypes.oneOf([Markdown, MarkdownHtml]),
  MarkdownProps: PropTypes.object.isRequired,
  TopBarProps: PropTypes.object.isRequired,
  IntroProps: PropTypes.object.isRequired,
  FooterProps: PropTypes.object.isRequired
};

export default PublicationPage;
