import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyle = makeStyles(theme => ({
  root: {
    padding: theme.inset.small,
    [theme.breakpoints.up('md')]: {
      padding: theme.inset.medium
    }
  },
  topline: {
    display: 'block',
    color: theme.palette.text.mainLowContrast,
    marginBottom: theme.stack.xsmall
  },
  text: {
    color: theme.palette.text.main
  }
}));

const IntroText = props => {
  const { components, componentsProps, topline, children, className } = props;
  const RootComponent = components.root ? components.root : 'div';
  const classes = useStyle(props);

  return (
    <RootComponent
      {...componentsProps.root}
      className={clsx(classes.root, className)}
    >
      {topline && (
        <Typography
          {...componentsProps.topline}
          className={classes.topline}
          component={components.topline}
          variant="overline"
        >
          {topline}
        </Typography>
      )}
      <Typography
        {...componentsProps.text}
        className={classes.text}
        component={components.text}
        variant="subtitle1"
      >
        {children}
      </Typography>
    </RootComponent>
  );
};

IntroText.propTypes = {
  className: PropTypes.string,
  topline: PropTypes.string,
  text: PropTypes.string,
  components: PropTypes.shape({
    root: PropTypes.elementType,
    topline: PropTypes.elementType,
    text: PropTypes.elementType
  }),
  componentsProps: PropTypes.shape({
    root: PropTypes.object,
    topline: PropTypes.object,
    text: PropTypes.object
  })
};

IntroText.defaultProps = {
  topline: '',
  text: '',
  components: {
    root: 'div',
    topline: 'span',
    text: 'p'
  },
  componentsProps: {
    root: {},
    topline: {},
    text: {}
  }
};

export default IntroText;
