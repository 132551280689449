import { graphql } from 'gatsby';
import { frontmatter } from '../helper/data';
import { markdownToHtml } from '../helper/transformation';

export function mapToProps(data = {}, isCms = false) {
  const fmData = isCms ? data : frontmatter(data);
  const introData = fmData.intro || {};
  const textMarkup = introData.textMarkdown
    ? markdownToHtml(introData.textMarkdown, ['a', 'strong', 'b', 'em', 'i'])
    : '';

  return {
    heading: introData.heading,
    textMarkup: textMarkup
  };
}

export const fragment = graphql`
  fragment Intro on MarkdownRemarkFrontmatter {
    intro {
      heading
      textMarkdown
    }
  }
`;
