import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.main,
    padding: theme.inset.small,
    [theme.breakpoints.up('md')]: {
      padding: theme.inset.medium
    }
  }
}));

const Title = props => {
  const { text, component, className } = props;
  const classes = useStyles(props);

  return (
    <Typography
      className={clsx(classes.root, className)}
      component={component}
      variant="h2"
    >
      {text}
    </Typography>
  );
};

Title.propTypes = {
  text: PropTypes.string,
  component: PropTypes.elementType,
  className: PropTypes.string
};

Title.defaultProps = {
  text: '',
  component: 'h1'
};

export default Title;
