import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Title from '../../atoms/Title';
import IntroText from '../../molecules/IntroText';
import Grid from '../../base/Layout/Grid';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    '&::before': {
      gridColumn: '1 / -1',
      gridRow: '1',
      content: '""',
      display: 'block',
      height: theme.spacing(6),
      width: '100vw',
      left: '50%',
      right: '50%',
      marginLeft: '-50vw',
      marginRight: '-50vw',
      position: 'relative',
      backgroundColor: theme.palette.primary.main
    },
    '& $title': {
      gridColumn: '1 / -1',
      gridRow: '2',
      position: 'relative',
      marginTop: -44
    },
    '& $title::before': {
      content: '""',
      display: 'inline-flex',
      position: 'absolute',
      right: 0,
      top: 0,
      backgroundColor: theme.palette.secondary.main,
      marginRight: theme.grid.xs.inset * -1,
      width: theme.grid.xs.inset,
      height: '100%'
    },
    '& $introText': {
      paddingLeft: 0,
      paddingRight: 0,
      gridColumn: '1 / -1',
      gridRow: '3',
      '& a': {
        color: theme.palette.text.main
      }
    },
    [theme.breakpoints.up('sm')]: {
      '& $title::before': {
        marginRight: theme.grid.sm.inset * -1,
        width: theme.grid.sm.inset
      },
      '& $introText': {
        paddingLeft: theme.inset.small,
        paddingRight: theme.inset.small
      }
    },
    [theme.breakpoints.up('md')]: {
      '&::before': {
        height: theme.spacing(9)
      },
      '& $title': {
        gridColumn: '2/-1',
        marginTop: -98,
        '&::before': {
          marginRight: theme.grid.md.inset * -1,
          width: theme.grid.md.inset
        }
      },
      '& $introText': {
        paddingLeft: 0,
        paddingRight: 0,
        gridColumn: '2/-1'
      }
    },
    [theme.breakpoints.up('lg')]: {
      '& $title': {
        '&::before': {
          marginRight: `calc( -${theme.grid.lg.inset}px + (100vw - ${theme.breakpoints.values.lg}px) / -2)`,
          width: `calc( ${theme.grid.lg.inset}px + (100vw - ${theme.breakpoints.values.lg}px) / 2)`
        }
      },
      '& $introText': {
        paddingLeft: theme.inset.medium,
        paddingRight: theme.inset.medium
      }
    }
  },
  gridContainer: {},
  title: {},
  introText: {}
}));

const KeyVisusal = props => {
  const { heading, headingLevel, textMarkup, textTopline, className } = props;
  const classes = useStyles(props);

  return (
    <Grid
      maxWidth="lg"
      className={clsx(classes.root, className)}
      component="section"
    >
      <Title
        className={classes.title}
        component={`h${headingLevel}`}
        text={heading}
      />
      <IntroText
        className={classes.introText}
        topline={textTopline}
        componentsProps={{
          text: {
            dangerouslySetInnerHTML: {
              __html: textMarkup
            }
          }
        }}
      />
    </Grid>
  );
};

KeyVisusal.propTypes = {
  heading: PropTypes.string.isRequired,
  headingLevel: PropTypes.number,
  textMarkup: PropTypes.string,
  textTopline: PropTypes.string,
  className: PropTypes.string,
  componentsProps: PropTypes.shape({
    title: PropTypes.object,
    introText: PropTypes.object
  })
};

KeyVisusal.defaultProps = {
  headingLevel: 1
};

export default KeyVisusal;
